import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet';

import Layout from '../layouts'

export default ({ data }) => {
console.log(data)
  const employee = data.airtable.data;

  return (
    <Layout>
      <Helmet title={`Evolve | ${employee.Name}`} />
      <h1>{employee.Name}</h1>
      {employee.Title ? <p>{employee.Title}</p> : ''}
      <p><strong>Email:</strong> {employee.Email}</p>
      <p>{employee.Phone}</p>
      {employee.Headshot &&
        <a href={`${employee.Headshot[0].thumbnails.full.url}`} target="_blank">
          <img
            src={`${employee.Headshot[0].thumbnails.large.url}`}
            alt={`Headshot of an employee at Evolve called ${employee.Name}`}
          />
        </a>
      }
      {employee.Assignments &&
        <ul>
          {employee.Assignments.map((assignment) => 
            <li key={assignment.id}>
              <span>{assignment.data.Project[0].data.Name}</span>
              <span> | </span>
              <span>{assignment.data.End_date ? `${assignment.data.Start_date} - ${assignment.data.End_date}` : `Current`}</span>
            </li>
          )}
        </ul>
      }
    </Layout>
  )
}

export const query = graphql`
  query SimpleEmpleyeeBySlug($name: String!) {
    airtable(table: {eq: "Employees"}, data: {Name: {eq: $name}}) {
      data {
        Name
        Email
        Phone
        Headshot {
          thumbnails {
            full {
              url
            }
            large {
              url
            }
          }
        }
        Assignments {
          id
          data {
            Start_date
            End_date
            Project {
              data {
                Name
                Customer {
                  data {
                    Name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
